
import { defineComponent, computed } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import BasketFooter from '@/components/BasketFooter.vue'
import useTravelPass from '@/composables/useTravelPass'
import { useRouter } from 'vue-router'
import BasketExpiryWarning from '@/components/BasketExpiryWarning.vue'
import TravelPass from '@/components/TravelPass.vue'
import { formatPrice, altFormatDate } from '@/helpers'
import useBasket from '@/composables/useBasket'
import { preventHistoryBack } from '@/utils/preventHistoryBack'

export default defineComponent({
  props: {
    basketId: {
      type: String
    }
  },
  components: {
    PageHeader,
    BasketFooter,
    BasketExpiryWarning,
    TravelPass
  },
  setup (props) {
    const {
      conditions,
      destinationCityName,
      originCityName,
      passengerCount,
      totalPrice,
      travelPassesFromBasket
    } = useTravelPass()
    const {
      getBasket,
      clearBasket
    } = useBasket()
    const router = useRouter()
    const travelPassType = '10 Journey Ticket'

    if (props.basketId) {
      travelPassesFromBasket(props.basketId)
      clearBasket()
      getBasket(props.basketId)
      preventHistoryBack()
    }

    const passengersType = computed(() => {
      const condition = conditions.value.find(condition =>
        condition.quantity === passengerCount.value
      )

      if (condition) {
        return condition.fareType
      } else {
        return 'passengers'
      }
    })

    const nextStep = () => {
      router.push({ name: 'TravelPass Buyer Details' })
    }

    return {
      destinationCityName,
      originCityName,
      conditions,
      nextStep,
      formatPrice,
      travelPassType,
      altFormatDate,
      totalPrice,
      passengerCount,
      passengersType
    }
  }
})
