import { Condition, TravelPassDestination, ZonePairPrice } from '@/models/TravelPassDestination'
import useTravelPass from '@/composables/useTravelPass'
import { Fares } from '@/helpers/fares'
import { BusStop } from '@/models/BusStop'

const { conditions } = useTravelPass()

const zonePairPriceForCondition = (
  condition: Condition,
  originStopId: number,
  destinationStopId: number
): ZonePairPrice | undefined =>
  condition.ZonePairPrices.find(({ FirstZoneStopIds: fz, SecondZoneStopIds: sz }) =>
    (fz.includes(originStopId) && sz.includes(destinationStopId)) ||
    (fz.includes(destinationStopId) && sz.includes(originStopId))
  )

export const addConditions = (
  inputConditions: Condition[],
  originStop: BusStop,
  destinationStop: BusStop,
  fares: Fares
) => {
  conditions.value = []

  inputConditions.forEach(condition => {
    const zonePairPrice = zonePairPriceForCondition(
      condition,
      originStop.BusStopId,
      destinationStop.BusStopId
    )

    if (!zonePairPrice) return

    conditions.value.push({
      fareType: condition.PassengerType,
      quantity: fares[condition.PassengerType.toLowerCase()] || 0,
      conditionId: condition.ConditionId,
      zonePairPriceId: zonePairPrice.ZonePairPriceId,
      price: zonePairPrice.Price,
      activationDates: []
    })
  })
}

export const filterStops = (
  stops: BusStop[] | undefined,
  travelPassDestinations: TravelPassDestination[],
  currentOriginStopId: number
): BusStop[] | undefined => {
  const stopIds: number[] = travelPassDestinations.map(destination =>
    destination.Conditions[0].ZonePairPrices.map(({ FirstZoneStopIds, SecondZoneStopIds }) => {
      let arr: number[] = []

      if (!FirstZoneStopIds.includes(currentOriginStopId)) arr = FirstZoneStopIds
      if (!SecondZoneStopIds.includes(currentOriginStopId)) arr = [...arr, ...SecondZoneStopIds]

      return arr
    }))
    .flat(2)
    .filter((value, index, self) => self.indexOf(value) === index)

  return stops?.filter(stop =>
    stop.BusStopId !== currentOriginStopId && stopIds.includes(stop.BusStopId)
  )
}

const zonePairPriceFor = (stop: BusStop, condition: Condition | undefined): boolean =>
  !!condition?.ZonePairPrices.some(zonePairPrice =>
    zonePairPrice.FirstZoneStopIds.includes(stop.BusStopId) ||
    zonePairPrice.SecondZoneStopIds.includes(stop.BusStopId)
  )

export const availableFaresForStop = (
  stop: BusStop | undefined,
  conditions: Condition[] | undefined
): string[] => {
  const fareTypes = ['adult', 'child', 'student']

  if (!stop) return fareTypes
  if (!conditions) return []

  const availableFares: string[] = []

  fareTypes.forEach(fareType => {
    const condition = conditions.find(condition =>
      condition.PassengerType.toLowerCase() === fareType
    )

    if (zonePairPriceFor(stop, condition)) availableFares.push(fareType)
  })

  return availableFares
}
