import { PassengerGroup, PassengerGroupType } from '@/models/Passenger'
import { computed, provide, reactive, readonly, toRefs } from 'vue'
import {
  getTravelPassesFromBasket
} from '@/expressway-api/travelPasses.api'
import { fareStrings } from '@/models/FareClass'

interface Condition {
  fareType: string;
  conditionId: string;
  zonePairPriceId: string;
  quantity: number;
  price: number;
  activationDates: string[];
}

const travelPassState = reactive({
  travelServiceId: '',
  originCityName: '',
  destinationCityName: '',
  conditions: [] as Condition[]
})

const totalPrice = computed(() =>
  travelPassState.conditions.reduce((a, b) => a + b.price * b.quantity, 0)
)

const passengers = computed((): PassengerGroup[] => {
  const passengers: PassengerGroupType[] = travelPassState.conditions.map(condition => (
    {
      price: condition.price,
      passengerType: condition.fareType,
      passengerTypeDisplay: fareStrings[condition.fareType],
      quantity: condition.quantity
    }
  ))

  return [{
    groupType: 'Travel Pass',
    passengers: passengers
  }]
})

const passengerCount = computed(() =>
  travelPassState.conditions.reduce((a, b) => a + b.quantity, 0)
)

const travelPassesFromBasket = (basketId: string) => {
  if (!basketId) throw Error('Cannot continue without a basket')
  getTravelPassesFromBasket(basketId)
    .then(travelPasses => {
      travelPassState.originCityName = travelPasses[0].ValidityZonePair.SecondZoneName
      travelPassState.destinationCityName = travelPasses[0].ValidityZonePair.FirstZoneName
      travelPasses.forEach(travelPass => {
        const index = travelPassState.conditions.findIndex(p =>
          p.fareType === travelPass.BonusSchemeGroupName
        )

        if (index >= 0) {
          travelPassState.conditions[index].quantity++
          travelPassState.conditions[index].activationDates = [
            ...travelPassState.conditions[index].activationDates,
            travelPass.CanBeActivatedUntil
          ]
        } else {
          travelPassState.conditions.push({
            fareType: travelPass.BonusSchemeGroupName,
            price: travelPass.Price,
            quantity: 1,
            activationDates: [travelPass.CanBeActivatedUntil],
            conditionId: '',
            zonePairPriceId: ''
          })
        }
      })
    })
}

export default function useTravelPass () {
  provide('pricingPassengers', readonly(passengers))
  provide('totalPrice', readonly(totalPrice))
  provide('passengerCount', readonly(passengerCount))
  provide('promoCode', null)

  return {
    ...toRefs(travelPassState),
    passengerCount: readonly(passengerCount),
    totalPrice: readonly(totalPrice),
    travelPassesFromBasket
  }
}
