
import {
  defineComponent,
  toRefs,
  reactive,
  nextTick,
  ref,
  computed,
  toRef,
  watch,
  Ref
} from 'vue'
import Spinner from '@/components/Spinner.vue'
import StopSearch from '@/components/StopSearch.vue'
import ClearableInput from '@/components/ClearableInput.vue'
import JourneyDatePicker from '@/components/DatePicker/JourneyDatePicker.vue'
import { BusStop } from '@/models/BusStop'
import { TravelPass } from '@/models/TravelPass'
import dayjs from 'dayjs'
import router from '@/router'
import useSwrv from 'swrv'
import { getAllBusStops } from '@/expressway-api/busStops.api'
import { getTravelPass } from '@/expressway-api/travelPasses.api'
import GenericError from '@/components/GenericError.vue'

interface StopData {
  visible: boolean;
  element: null | typeof StopSearch;
  label: string;
  stop: Ref<BusStop | null>;
  stops: Ref<BusStop[] | undefined>;
}

export default defineComponent({
  components: {
    StopSearch,
    JourneyDatePicker,
    ClearableInput,
    Spinner,
    GenericError
  },
  props: {
    travelPassNumber: {
      type: Number,
      required: true
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const valid = ref(false)
    const { data: stops, error } = useSwrv('busStops', getAllBusStops)
    const dataset = reactive({
      oneWay: false,
      promoCode: '',
      departureDate: dayjs().format('YYYY-MM-DD'),
      returnDate: dayjs().format('YYYY-MM-DD'),
      originStop: null,
      destinationStop: null
    })
    const originStopIds = ref(Array<number>())
    const destinationStopIds = ref(Array<number>())
    const fare = ref('')
    const travelPass = ref({} as TravelPass)
    const activatedOn = ref(dayjs().format('YYYY-MM-DD'))
    const expiresOn = ref(dayjs().format('YYYY-MM-DD'))
    const loading = ref(true)
    const expired = computed(() => dayjs(expiresOn.value) < dayjs() || false)
    const currentStopId = ref(0)
    const filteredOriginStops = computed(() =>
      stops.value?.filter(stop => originStopIds.value.includes(stop.BusStopId)))
    const filteredDestinationStops = computed(() =>
      stops.value?.filter(stop =>
        stop.BusStopId !== currentStopId.value && destinationStopIds.value.includes(stop.BusStopId)
      )
    )

    const stopState = reactive<{ [index: string]: StopData }>({
      origin: {
        visible: false,
        element: null,
        label: 'Where are you travelling from?',
        stop: toRef(dataset, 'originStop'),
        stops: ref(filteredOriginStops)
      },
      destination: {
        visible: false,
        element: null,
        label: 'Where would you like to go?',
        stop: toRef(dataset, 'destinationStop'),
        stops: ref(filteredDestinationStops)
      }
    })

    getTravelPass(props.travelPassNumber).then(travelPasses => {
      [travelPass.value] = travelPasses

      fare.value = `${travelPass.value.BonusSchemeGroupName}, 1`

      originStopIds.value = [
        ...travelPass.value.ValidityZonePair.SecondZoneBusStops.map(busStop => busStop.BusStopId),
        ...travelPass.value.ValidityZonePair.FirstZoneBusStops.map(busStop => busStop.BusStopId)
      ]
      destinationStopIds.value = [
        ...travelPass.value.ValidityZonePair.FirstZoneBusStops.map(busStop => busStop.BusStopId),
        ...travelPass.value.ValidityZonePair.SecondZoneBusStops.map(busStop => busStop.BusStopId)
      ]

      if (dayjs(travelPass.value.ActivatedOn).isAfter(dayjs())) {
        activatedOn.value = travelPass.value.ActivatedOn
      }

      expiresOn.value = travelPass.value.ValidUntil
      dataset.departureDate = dayjs(activatedOn.value).format('YYYY-MM-DD')
      dataset.returnDate = dataset.departureDate

      loading.value = false
    })

    const showSearch = (key: string) => {
      stopState[key].visible = true
      nextTick(() => stopState[key].element?.focus())
    }

    const checkDatePresence = () =>
      dataset.departureDate && (dataset.returnDate || dataset.oneWay)

    const validateTrip = () => {
      valid.value =
        !!(checkDatePresence() &&
        stopState.origin.stop?.BusStopId &&
        stopState.destination.stop?.BusStopId)
    }

    const search = () => {
      router.push({
        name: 'TravelPass Select Journey',
        query: {
          originId: stopState.origin.stop?.BusStopId.toString(),
          destinationId: stopState.destination.stop?.BusStopId.toString(),
          departureDate: dataset.departureDate,
          returnDate: dataset.oneWay ? '' : dataset.returnDate,
          isPartOfRoundTrip: dataset.oneWay ? 'false' : 'true',
          isOutbound: 'true',
          fares: fare.value.toLowerCase(),
          travelPassNumber: props.travelPassNumber
        }
      })
    }

    watch([dataset, () => stopState.origin.stop, () => stopState.destination.stop], () => {
      validateTrip()
      currentStopId.value = stopState?.origin?.stop?.BusStopId || 0
    }, { deep: true })

    return {
      showSearch,
      valid,
      stopState,
      search,
      stops,
      error,
      activatedOn,
      expiresOn,
      expandPassengers: ref(false),
      loading,
      travelPass,
      expired,
      router,
      ...toRefs(dataset)
    }
  }
})
