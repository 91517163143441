
import {
  defineComponent,
  reactive,
  Ref,
  toRef,
  nextTick,
  ref,
  computed,
  PropType,
  watch
} from 'vue'

import StopSearch from '@/components/StopSearch.vue'
import { BusStop } from '@/models/BusStop'
import { TravelPassDestination } from '@/models/TravelPassDestination'
import dayjs from 'dayjs'
import PassengerSelect from '@/components/PassengerSelect.vue'
import router from '@/router'
import { urlEncodeFares } from '@/helpers/fares'
import { Fares } from '@/models/FareClass'
import { getAllBusStops } from '@/expressway-api/busStops.api'
import { getTravelPassDestinations } from '@/expressway-api/travelPasses.api'
import useSwrv from 'swrv'
import useTravelPass from '@/composables/useTravelPass'
import { addConditions, filterStops, availableFaresForStop } from '@/helpers/travelPasses'
import Spinner from '@/components/Spinner.vue'

interface StopData {
  visible: boolean;
  element: null | typeof StopSearch;
  label: string;
  stop: Ref<BusStop | undefined>;
  stops: Ref<BusStop[] | undefined>;
  error: unknown;
}

const journeyData = {
  oneWay: false,
  promoCode: '',
  departureDate: dayjs().format('YYYY-MM-DD'),
  returnDate: dayjs().format('YYYY-MM-DD'),
  originStop: undefined,
  destinationStop: undefined
}

const loadFares = (fares: Fares, passengers: Fares) => {
  fares.adult = passengers.adult || 0
  fares.child = passengers.child || 0
  fares.student = passengers.student || 0
}

const ADULT = { adult: { caption: '16+ years' } }
const CHILD = { child: { caption: '4 - 16 years' } }
const STUDENT = {
  student: {
    caption: 'With Valid Student Card',
    infoPath: 'passenger_info___student'
  }
}

export default defineComponent({
  components: {
    StopSearch,
    PassengerSelect,
    Spinner
  },
  props: {
    destinationId: {
      type: Number
    },
    originId: {
      type: Number
    },
    passengers: {
      type: Object as PropType<Fares>
    }
  },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const { data: stops, error } = useSwrv('busStops', getAllBusStops)
    const travelPassDestinations = ref(Array<TravelPassDestination>())
    const currentOriginStopId = ref(props.originId || 0)
    const fetchingDestinations = ref(false)
    const { destinationCityName, originCityName, travelServiceId } = useTravelPass()
    const loading = ref(true)
    const dataset = reactive(journeyData)
    const fares = reactive({ adult: 1, child: 0, student: 0 } as Fares)
    if (props.passengers) loadFares(fares, props.passengers)
    const stopState = reactive<{ [index: string]: StopData }>({
      origin: {
        visible: false,
        element: null,
        label: 'Where are you travelling from?',
        stop: toRef(dataset, 'originStop'),
        stops: stops,
        error: error
      },
      destination: {
        visible: false,
        element: null,
        label: 'Where would you like to go?',
        stop: toRef(dataset, 'destinationStop'),
        stops: ref(undefined),
        error: error
      }
    })

    const passengerTypes = computed(() => {
      const passengers = {}
      const availableFares: string[] = availableFaresForStop(
        stopState?.destination?.stop,
        travelPassDestinations?.value[0]?.Conditions
      )

      /* eslint-disable vue/no-side-effects-in-computed-properties */
      availableFares.includes('adult') ? Object.assign(passengers, ADULT) : fares.adult = 0
      availableFares.includes('child') ? Object.assign(passengers, CHILD) : fares.child = 0
      availableFares.includes('student') ? Object.assign(passengers, STUDENT) : fares.student = 0

      return passengers
    })

    watch(stops, () => {
      stopState.destination.stop = stops.value?.find(stop =>
        stop.BusStopId === props.destinationId)

      stopState.origin.stop = stops.value?.find(stop =>
        stop.BusStopId === props.originId)

      loading.value = false
    })

    const showSearch = (key: string) => {
      stopState[key].visible = true
      nextTick(() => stopState[key].element?.focus())
    }

    const fetchDestinations = async (stopId: number): Promise<void> => {
      fetchingDestinations.value = true
      travelPassDestinations.value = await getTravelPassDestinations(stopId)
      travelServiceId.value = travelPassDestinations.value[0].TravelServiceId
      stopState.destination.stops =
        filterStops(stops.value, travelPassDestinations.value, currentOriginStopId.value)
      fetchingDestinations.value = false
    }

    const closeJourney = (journey: StopData, journeyName: string) => {
      journey.visible = false

      if (!stopState.origin.stop) return

      currentOriginStopId.value = stopState.origin.stop.BusStopId
      if (journeyName === 'origin') fetchDestinations(currentOriginStopId.value)
    }

    const passengerNumber = computed((): number => Object.keys(fares).reduce(
      (passengersNumber: number, key: string): number => passengersNumber + (fares[key] || 0), 0))

    const valid = computed((): boolean =>
      !fetchingDestinations.value &&
      !!stopState.origin.stop &&
      !!stopState.destination.stop &&
      passengerNumber.value > 0
    )

    const confirm = () => {
      if (
        !stopState.origin.stop ||
        !stopState.destination.stop ||
        fetchingDestinations.value
      ) return

      const conditions = travelPassDestinations.value[0].Conditions
      originCityName.value = stopState.origin.stop.CityName
      destinationCityName.value = stopState.destination.stop.CityName

      addConditions(conditions, stopState.origin.stop, stopState.destination.stop, fares)

      router.push({ name: 'Select TravelPass Date', query: { fares: urlEncodeFares(fares) } })
    }

    const passengersText = computed((): string =>
      `(${passengerNumber.value} passenger${passengerNumber.value !== 1 ? 's' : ''})`
    )

    return {
      stopState,
      showSearch,
      fares,
      expandPassengers: ref(false),
      passengerTypes,
      valid,
      confirm,
      closeJourney,
      travelPassDestinations,
      passengersText,
      loading
    }
  }
})
